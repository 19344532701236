import React from 'react';

import shape from './shape.svg'
import line from './line.svg'

import Box from '../../components/Box';
import Flex from '../../components/Flex';
import Text from '../../components/Text';
import Image from '../../components/Image';
import { mobileOrDesktop } from '../../components/ThemeProvider/theme'

const legneds = [
  {
    src: shape,
    text: '上一次脫單的月份'
  },
  {
    src: line,
    text: '想要戀愛的月份'
  },
]

const Legend = ({ vote }) => {
  return (
    <Flex justifyContent="space-around" mt={vote ? '1.5em' : '0em'}>
      {legneds.map((l, i) => (
        <Flex alignItems="center" key={i} pr={mobileOrDesktop('0em', '1.5em')}>
          <Box width={mobileOrDesktop('1.2em', '1.5em')} mr={mobileOrDesktop('0.5em', '1em')}>
            <Image src={l.src} />
          </Box>
          <Text.jin>{l.text}</Text.jin>
        </Flex>
      ))}
    </Flex>
  );
};

export default Legend;
