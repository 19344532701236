import React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from 'styled-system';

import SVG from './SVG';
import { customColor } from './utils/getColor';

const activeText = css`
  ${customColor('hoverColor', 'fill')};
`;

const activePath = css`
  ${customColor('hoverBg', 'fill')};
  ${customColor('hoverBorder', 'stroke')};
`;

const active = css`
  text {
    ${(props) => !props.disabled && activeText}
  }
  path {
    ${(props) => !props.disabled && activePath}
  }
`

const G = styled.g`
  cursor: pointer;
  text {
    ${customColor('color', 'fill')};
    transition: all ${themeGet('duration', 250)}ms;
  }
  path {
    ${customColor('bg', 'fill')};
    ${customColor('borderColor', 'stroke')};
    transition: all ${themeGet('duration', 250)}ms;
    stroke-width: 1px;
  }
  &:hover,
  &:focus {
    ${active}
  }
  ${(props) => props.active && !props.disabled && active(props)}
  ${(props) => props.disabled && `
    cursor: not-allowed;
    opacity: 0.5;
  `}
`

const ShapeButton = ({ text, onClick, hoverBg, hoverBorder, hoverColor, ...props }) => (
  <SVG viewBox="0 0 178.26 180.41" fontFamily="Noto Serif TC" {...props}>
    <G color="primary" borderColor="black" bg="white" onClick={onClick} hoverBg={hoverBg} hoverBorder={hoverBorder} hoverColor={hoverColor}>
      <path d="M120.36,34c17.08,5.7,27.74,11.61,32.22,17.6,10,13.53,10.31,19.82,7.84,36.59s-9.09,15.29-17.77,30.63-3.88,22.82-16.88,30.78S93,154,61.43,140.27c-16-7-28.26.72-37.07-11.7S23.83,108,26.19,90.51s-.89-19.43,7.54-32.1,12.38-10.31,26-18.69c6.1-3.72,14.4-13.49,28.72-14.66C96.72,24.45,120.36,34,120.36,34Z" />
      <text fontWeight="700" transform="translate(90.84 97.99)" textAnchor="middle" fontSize="22" letterSpacing="0.05em">{text}</text>
    </G>
  </SVG>
)

ShapeButton.defaultProps = {
  hoverColor: 'white',
  hoverBg: 'primary',
  hoverBorder: 'primary',
  display: 'inline-block',
};

export default ShapeButton;
