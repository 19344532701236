import React from 'react';
import set from 'lodash/set';
import range from 'lodash/range';

import Radar from '../../components/Radar';

const transformValues = (values, count) => range(12).reduce((months, i) => set(months, i + 1, values[i] / count || 0), {})

const HomeRadar = ({ shape: { want, real, wantCount, realCount }, transition }) => {
  const max = Math.ceil(Math.max(...want.map(n => n / wantCount * 10), ...real.map(n => n / realCount * 10)));
  const data = [
    {
      data: transformValues(want, wantCount),
      meta: { class: 'want' }
    },
    {
      data: transformValues(real, realCount),
      meta: { class: 'real' }
    }
  ]
  return (
    <Radar data={data} max={max / 10} scales={max} format={(n) => `${Math.round(n * 100)}%`} transition={transition} />
  );
}

export default HomeRadar;
