import React, { PureComponent } from 'react'
import { scroller } from 'react-scroll'

import HomePage from '../containers/HomePage';

import gaTrack from '../utils/gaTrack'

class IndexPage extends PureComponent {
  state = {
    vote: false,
    thanks: false
  };

  handleOpenVote = () => {
    this.setState({ vote: true }, () => {
      scroller.scrollTo('vote', { smooth: true, duration: 500 })
    })
    gaTrack({ category: '投票', label: '開始投票' })
  }

  handleCloseVote = () => {
    this.setState({ vote: false })
    gaTrack({ category: '投票', label: '取消投票' })
  }

  handleOpenThanks = () => {
    this.setState({ thanks: true })
    gaTrack({ category: '投票', label: '投票完持' })
  }
  handleCloseThanks = () => this.setState({ thanks: false })

  render() {
    const { vote, thanks } = this.state;
    return (
        <HomePage
          onClick={this.handleOpenVote}
          handleCloseVote={this.handleCloseVote}
          openThanks={this.handleOpenThanks}
          closeThanks={this.handleCloseThanks}
          vote={vote}
          thanks={thanks}
        />
    )
  }
}

export default IndexPage
