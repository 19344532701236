import React from 'react';

import Vote from './Vote'
import Legend from './Legend'

import Box from '../../components/Box';
import Flex from '../../components/Flex';
import ShapeButton from '../../components/ShapeButton';
import { mobileOrDesktop } from '../../components/ThemeProvider/theme'
import Count from './Count';

const Down = ({
    onClick,
    vote,
    firebase,
    isMobile,
    handleOpen,
    handleClose,
    showModal,
    shape,
    handleCloseVote,
    ...props
  }) => {
  return (
    <Box {...props}>
      <Flex
        mt={mobileOrDesktop('0', '3em')}
        flexDirection={mobileOrDesktop('row', 'column')}
        alignItems={mobileOrDesktop('center', 'unset')}
        justifyContent={mobileOrDesktop('space-between', 'unset')}
        px={mobileOrDesktop('1em', '0')}
      >
        {!isMobile && (
          <Legend vote={vote} />
        )}
        {isMobile && shape && (
          <Count count={shape.wantCount} px={mobileOrDesktop('1em', '0')} />
        )}
        <Box textAlign="right" opacity={vote ? 0 : 1} pointerEvents={vote && 'none'}>
          <ShapeButton
            width="8em"
            text="我要投票"
            transform={mobileOrDesktop('unset','translateX(-30%)')}
            onClick={onClick}
          />
        </Box>
      </Flex>
      <Box
        position={mobileOrDesktop('static', 'absolute')}
        left={vote ? '50%' : '0'}
        top="0"
        width="100%"
        transform={mobileOrDesktop('none', vote ? 'translate(-50%, -20%)' : 'translate(-150%, -20%)')}
        transition={mobileOrDesktop('unset', 'all 500ms')}
        display={mobileOrDesktop(vote ? 'block' : 'none', 'block')}
        borderRight={mobileOrDesktop('none', '1px solid gray')}
      >
        <Vote
          firebase={firebase}
          vote={vote}
          isMobile={isMobile}
          handleOpen={handleOpen}
          handleClose={handleClose}
          showModal={showModal}
          handleCloseVote={handleCloseVote}
        />
      </Box>
    </Box>
  );
};

export default Down;
