import React from 'react';
import { compose } from 'redux'

import Box from '../../components/Box';
import PageStart from '../../components/PageStart';
import Page from '../Page'
import HomeRadar from './HomeRadar'
import Down from './Down'
import Count from './Count'

import { selectFirebaseData } from '../../services/firebase/selectors'
import { mobileOrDesktop } from '../../components/ThemeProvider/theme'
import withResponsive from '../../hoc/withResponsive'
import Legend from './Legend';

const Home = ({
  shape,
  firebase,
  onClick,
  vote,
  isMobile,
  thanks,
  openThanks,
  closeThanks,
  handleCloseVote,
}) => {
  const down = (
    <Down
      firebase={firebase}
      onClick={onClick}
      vote={vote}
      isMobile={isMobile}
      handleOpen={openThanks}
      handleClose={closeThanks}
      showModal={thanks}
      shape={shape}
      handleCloseVote={handleCloseVote}
    />
  );
  const left = (
    <Box position="relative">
      <PageStart
        firstTitle={isMobile ? '哪一個月最容易脫單？' : '哪一個月'}
        secondTitle={isMobile ? null : '最容易脫單？'}
        descrip="來看看大家都是在哪個月份「想要戀愛」和「脫單成功」！"
      />
      {isMobile || down}
    </Box>
  );

  const right = (
    <Box >
      {shape && (
        <Box position="relative" mx={mobileOrDesktop('5%', '10%')} mt="2em">
          {isMobile && (
            <Legend vote={vote} />
          )}
          <HomeRadar shape={shape} />
          {!isMobile && (
            <Count count={shape.wantCount} />
          )}
        </Box>
      )}
      {isMobile && down}
    </Box>
  );

  return (
    <Page left={left} right={right} />
  );
};

export default compose(
  withResponsive,
  selectFirebaseData(['shape'])
)(Home);
