import React from 'react';

import Box from '../../components/Box';
import Text from '../../components/Text';
import Title from '../../components/Title';
import { mobileOrDesktop } from '../../components/ThemeProvider/theme'

const thousandComma = (number) => {
 let num = number.toString();
 const pattern = /(-?\d+)(\d{3})/;
 while(pattern.test(num))
 {
  num = num.replace(pattern, "$1,$2");
 }
 return num;
}

const Count = ({count ,...props}) => {
  return (
    <Box
      position={mobileOrDesktop('relative', 'absolute')}
      bottom="0"
      right="0"
      textAlign={mobileOrDesktop('left', 'right')}
      {...props}
    >
      <Title fontSize="1em" minWidth="unset">累積票數</Title>
      <Text fontSize="2em" color="primary">{thousandComma(count)}<Text.inline color="black" fontSize="0.5em">票</Text.inline></Text>
    </Box>
  );
};

export default Count;
