import React from 'react';
import { withFormik } from 'formik';
import { object, number } from 'yup'
import { Element } from 'react-scroll'
import isNumber from 'lodash/isNumber'

import Box from '../../components/Box'
import Text from '../../components/Text'
import Dropdown from '../../components/Dropdown'
import ShapeButton from '../../components/ShapeButton'
import Button from '../../components/Button'
import Flex from '../../components/Flex';
// import Text from '../../components/Text';
import Title from '../../components/Title';
import Image from '../../components/Image';
import heart from './heart.svg';

import { mobileOrDesktop } from '../../components/ThemeProvider/theme'
import Modal from '../../components/Modal';

const months = [
  '一月 January',
  '二月 February',
  '三月 March',
  '四月 April',
  '五月 May',
  '六月 June',
  '七月 July',
  '八月 August',
  '九月 September',
  '十月 October',
  '十一月 November',
  '十二月 December',
].map((label, index) => ({ value: index, label }));

const drops = [
  {
    name: 'want',
    q: '你一年中最想要談戀愛的月份是？'
  },
  {
    name: 'real',
    q: '你上次實際脫單成功的月份是？'
  },
]

const Vote = ({
    isSubmitting,
    handleChange,
    handleSubmit,
    handleClose,
    values,
    status,
    isMobile,
    showModal,
    handleCloseVote,
    firebase
  }) => {
  return (
    <Box>
      {status === 'submitted' ? (
        <Modal noXX isOpen={showModal} onRequestClose={handleClose} width="10em">
          <Box width="3em" mx="auto" my="1em">
            <Image src={heart} />
          </Box>
          <Text textAlign="center">投票已送出</Text>
        </Modal>
      ) : (
        <Element name="vote">
          <Box
            textAlign="center"
            bg="white"
          >
            <Box display={mobileOrDesktop('none', 'block')}>
              <Box width="3em" mx="auto" pb="1em">
                <Image src={heart} />
              </Box>
              <Title fontSize="1.5em">戀愛月份投票所</Title>
            </Box>
            <form>
              <Flex
                flexDirection={mobileOrDesktop('row', 'column')}
                justifyContent={mobileOrDesktop('center', 'unset')}
                mb={mobileOrDesktop('1.5em', '3em')}
                mt="1em"
              >
                {drops.map((drop, i) => (
                  <Box px="1em" key={i} borderRight={isMobile ? (i < 1 ? '1.5px solid black' : null) : 'unset'}>
                    <Box
                      mt={mobileOrDesktop('0', '2em')}
                      fontSize={mobileOrDesktop('1.25em', '1em')}
                      fontWeight={mobileOrDesktop('normal', 'bold')}
                      pb="0.25em"
                    >
                      {drop.q}
                    </Box>
                    {!isMobile && <Box mx="35%" borderTop={i < 1 ? '5px dotted' : '1.5px solid'} borderColor="primary" />}
                    <Box mx={mobileOrDesktop('0em', '4em')} mt="1em">
                      <Dropdown
                        name={drop.name}
                        onChange={handleChange}
                        value={values[drop.name]}
                        options={i ? months.concat({ label: '沒有過T_T', value: '' }) : months}
                        placeholder="選擇一個月份"
                        native={isMobile}
                      />
                    </Box>
                  </Box>
                ))}
              </Flex>
              <Flex justifyContent="center" alignItems="center">
                <Box>
                  <Button.minimal type="button" onClick={handleCloseVote} px="1.5em" disabled={isSubmitting}>
                    取消
                  </Button.minimal>
                </Box>
                <Box>
                  <ShapeButton onClick={handleSubmit} width="8em" text="送出" disabled={isSubmitting} />
                </Box>
              </Flex>
              {status}
            </form>
          </Box>
        </Element>
      )}
    </Box>
  );
};

export default withFormik({
  validationSchema: object().shape({
    want: number().min(0).max(11).integer().required(),
    real: number().min(0).max(11).integer(),
  }),
  handleSubmit : (values, { setStatus, setSubmitting, props: { firebase, handleOpen } }) => {
    setSubmitting(true);
    firebase.push('/answers', {
      want: +values.want,
      real: isNumber(values.real) ? +values.real : null,
      timestamp: firebase.database.ServerValue.TIMESTAMP,
    }).then(() => {
      setSubmitting(false)
      handleOpen()
      setStatus('submitted')
    }).catch((err) => {
      console.error(err)
      setSubmitting(false)
      setStatus('something wrong')
    })
  }
})(Vote);
